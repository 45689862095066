import { functions, httpsCallable } from '@util/firebase';
import { logError } from '@util/logError';

import { ProductDocument } from '@util/types/firestore/products';
import { MasterDocument } from '@util/firestore/master';
import { BlogDocument } from '@util/types/firestore/blog';

import { Item, EventTypes, PixelEvents } from './analytics.types';
import { CartItem } from '@util/types/firestore/carts';

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
const isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_TRACKING_ID

const fbq:
  | undefined
  | ((
      type: 'track',
      event: PixelEvents,
      params?: any,
      eventID?: { eventID: string }
    ) => void) =
  typeof window === 'undefined' ? undefined : (window as any).fbq;

// all GA4 events not listed below
export async function logEvent(
  eventName: EventTypes,
  eventParams?: {
    coupon?: string | undefined;
    currency?: string | undefined;
    items?: Item[];
    payment_type?: string | undefined;
    value?: number | undefined;
    [key: string]: any;
  },
  uid?: string
) {
  if (!isProd) return;

  try {
    // fb pixel events
    if (eventName === 'add_payment_info' && eventParams?.id && typeof eventParams?.is_payout === 'boolean') {
      addPaymentInfo({ id: eventParams.id, is_payout: eventParams.is_payout });
    }
    if (eventName === 'add_to_wishlist' && eventParams && uid) addToWishlist(eventParams, uid);
    if (eventName === 'purchase' && eventParams && uid) logPurchase(eventParams, uid);
    if (eventName === 'created_offer' && eventParams?.items?.[0] && uid) logOffer(eventParams.items[0], uid);

    pushToDataLayer(eventName, {
      ...eventParams,
      user_id: uid,
    });
  } catch (e) {
    logError(e);
  }
}

// Main analytics functions with extra config
export async function viewListing(
  item: ProductDocument,
  referrer: string,
  uid?: string
) {
  if (!isProd) return null;

  try {
    // Push to Firebase Cloud Function
    await httpsCallable(
      functions,
      'viewedProduct'
    )({
      item,
      total: item.price,
      from_web: true,
    });

    const eCommercePayload = {
      ecommerce: {
        currencyCode: "USD",
        detail: {
          products: [
            {
              source: item.external_source || 'gearfocus',
              name: item.title,
              id: item.id,
              image: item.thumbnail,
              price: item.price,
              brand: item.brand,
              categories: item.categories,
              master: item.master || null,
              slug: item.slug,
              url: `https://www.gearfocus.com/products/${item.slug}`,
            },
          ],
        },
      }
    }
    pushToDataLayer("rr_view_item", eCommercePayload);

    // Push to Firebase Cloud Function
    await httpsCallable(
      functions,
      'viewedProduct'
    )({
      item,
      total: item.price,
      from_web: true,
    });

    // Push to Facebook Pixel
    if (fbq) {
      fbq(
        'track',
        'ViewContent',
        {
          content_ids: [item.id],
          content_name: item.title,
          content_category: item.categories[0],
          content_type: referrer,
          contents: [{ id: item.id, quantity: 1 }],
          value: item.price,
          currency: 'USD',
        },
        { eventID: uid + ' ' + item.id }
      );
    }
  } catch (e) {
    logError(e);
  }
}

export async function viewMaster(
  item: MasterDocument,
  uid?: string
) {
  if (!isProd) return null;
  try {
    await httpsCallable(
      functions,
      'master-viewMasterProduct'
    )({
      item,
      from_web: true,
    });

    const eCommercePayload = {
      ecommerce: {
        currencyCode: "USD",
        detail: {
          products: [
            {
              source: 'gearfocus',
              master: true,
              name: item.title,
              id: item.id,
              image: item.images[0],
              brand: item.brand,
              series: item.series,
              model: item.model,
              slug: item.slug,
              url: `https://www.gearfocus.com/m/${item.slug}`,
            },
          ],
        },
      }
    }
    pushToDataLayer("rr_view_item", eCommercePayload);

  } catch (e) {
    logError(e);
  }
}

export async function viewBlogPost(
  item: BlogDocument,
  uid?: string
) {
  if (!isProd) return null;
  try {
    await httpsCallable(
      functions,
      'blog-viewedBlog'
    )({
      item,
      from_web: true,
    });

    pushToDataLayer("view_blog", {
      user_id: uid || null,
      title: item.title,
      url: `https://www.gearfocus.com/blog/${item.slug}`,
      categories: item.categories,
      tags: item.tags
    });
  } catch (e) {
    logError(e);
  }
}

export async function clickAffiliate(
  collection: string,
  document: string,
  source: string
) {
  if (!isProd) return null;
  try {
    await httpsCallable(
      functions,
      'affiliate-clickAffiliateLink'
    )({
      collection,
      document,
      source,
      from_web: true,
    });
  } catch (e) {
    logError(e);
  }
}

export async function logInitCheckout(
  items: CartItem[],
  total: number,
  uid?: string
) {
  if (!isProd) return;
  
  try {
    await httpsCallable(
      functions,
      'initiateCheckout'
    )({
      items,
      total,
      isComplete: false,
      from_web: true,
    });

    const mappedItems = items.map((item) => ({
      source: item.external_source || "gearfocus",
      name: item.product_title,
      id: item.product_id,
      sku: item.product_sku,
      image: item.product_image,
      price: item.product_cost,
      brand: item.product_brand,
      categories: item.product_categories,
      master: item.product_master || null,
      url: item.product_link
    }));

    const eCommercePayload = {
      ecommerce: {
        currencyCode: "USD",
        detail: {
          products: mappedItems,
        },
      }
    }
    pushToDataLayer("rr_begin_checkout", eCommercePayload);

    if (fbq) {
      fbq(
        'track',
        'InitiateCheckout',
        {
          content_ids: items.map((i) => i.product_id),
          contents: items.map((i) => {
            return { id: i.product_id, quantity: 1 };
          }),
          num_items: items.length,
          value: total,
          currency: 'USD',
        },
        { eventID: uid + ' ' + items[items.length - 1].product_id }
      );
    }
  } catch (e) {
    console.log("logInitCheckout error", e)
    logError(e);
  }
}

export async function logAddToCart(
  item: ProductDocument,
  total: number,
  uid: string
) {
  if (!isProd) return;

  try {
    await httpsCallable<Record<string, never>, void>(
      functions,
      'addToCart'
    )({});

    const eCommercePayload = {
      ecommerce: {
        currencyCode: "USD",
        add: {
          products: [
            {
              source: item.external_source || 'gearfocus',
              name: item.title,
              id: item.id,
              sku: item.sku,
              image: item.thumbnail,
              price: item.price,
              brand: item.brand,
              categories: item.categories,
              master: item.master || null,
              slug: item.slug,
              url: `https://www.gearfocus.com/products/${item.slug}`,
            },
          ],
        },
      }
    }
    pushToDataLayer("rr_add_to_cart", eCommercePayload);

    if (fbq) {
      fbq(
        'track',
        'AddToCart',
        {
          content_ids: [item.id],
          content_name: item.title,
          content_type: item.categories[0],
          contents: [{ id: item.id, quantity: 1 }],
          value: total,
          currency: 'USD',
        },
        { eventID: uid + '_' + item.id }
      );
    }
  } catch (e) {
    logError(e);
  }
}

export async function completeRegistration(
  uid: string,
  method: string,
  email: string
) {
  try {
    await httpsCallable<{ email: string; from_web: boolean }, void>(
      functions,
      'completeRegistration'
    )({ email, from_web: true });
    if (fbq)
      fbq(
        'track',
        'CompleteRegistration',
        {
          status: true,
          content_name: method,
        },
        { eventID: uid }
      );
    
    pushToDataLayer("signup", {user_id: uid, email: email});
  } catch (e) {
    logError(e);
  }
}

export async function becomeASeller(
  uid: string,
  email: string
) {
  try {
    pushToDataLayer("become_a_seller", { user_id: uid, email: email });
  } catch (e) {
    logError(e);
  }
}

function logPurchase(
  eventParams: { 
    items?: Item[] 
    customer?: {
      name: string,
      email: string, 
      phone: string | null,
      country: string, 
      region: string,
      city: string, 
      zip: string,
    },
    transaction?: {
      id: string,
      sellers: string[],
      total: number,
      tax: number,
      shipping: number,
      coupon?: string,
    }
  },
  uid: string
) {
  if (!isProd) return;

  try {
    const mappedItems = eventParams.items?.map((item) => ({
        source: item.external_source ?? "gearfocus",
        name: item.item_name ?? '',
        id: item.item_id ?? '',
        image: item.item_thumbnail ?? '',
        price: item.price ?? 0,
        brand: item.item_brand ?? '',
        categories: item.item_categories ?? [],
        master: item.master ?? null,
        slug: item.item_slug ?? '',
        url: `https://www.gearfocus.com/products/${item.item_slug ?? ''}`,
    }));

    const eCommercePayload = {
      ecommerce: {
          currencyCode: "USD",
          actionField: {
            id: eventParams.transaction?.id || "",
            sellers: eventParams.transaction?.sellers || [],
            total: eventParams.transaction?.total || "",
            tax: eventParams.transaction?.tax || "",
            shipping: eventParams.transaction?.shipping || "",
            coupon: eventParams.transaction?.coupon || "",
          },
          detail: {
            products: mappedItems,
          },
      }
    };
    pushToDataLayer("rr_purchase", {
      name: eventParams.customer?.name || "",
      email_address: eventParams.customer?.email || "",
      phone_number: eventParams.customer?.phone || "",
      customer_id: uid,
      country: eventParams.customer?.country || "",
      region: eventParams.customer?.region || "",
      city: eventParams.customer?.city || "",
      zip: eventParams.customer?.zip || "",
      ...eCommercePayload
    });

    if (fbq)
      fbq(
        'track',
        'Purchase',
        { value: eventParams.transaction?.total, currency: 'USD' },
        { eventID: uid + ' ' + (eventParams.transaction?.id ?? '') }
      );
  } catch (e) {
    logError(e);
  }
}

function addPaymentInfo(eventParams: { id: string; is_payout: boolean }) {
  try {
    if (fbq)
      fbq(
        'track',
        'AddPaymentInfo',
        {
          content_ids: [eventParams.id],
          content_category: eventParams.is_payout
            ? 'For Payout'
            : 'For Purchase',
        },
        { eventID: eventParams.id }
      );
  } catch (e) {
    logError(e);
  }
}

function addToWishlist(
  eventParams: {
    currency?: string;
    items?: Item[];
    value?: number;
  },
  uid: string
) {
  try {
    if (fbq)
      fbq(
        'track',
        'AddToWishlist',
        {
          content_ids: [eventParams.items?.[0]?.item_id ?? ''],
          content_name: eventParams.items?.[0]?.item_name ?? '',
          value: eventParams.value ?? 0,
          currency: eventParams.currency ?? 'USD',
        },
        { eventID: uid + ' ' + (eventParams.items?.[0]?.item_id ?? '') }
      );
  } catch (e) {
    logError(e);
  }
}

function logOffer(item: Item, uid: string) {
  try {
    if (fbq)
      fbq(
        'track',
        'Lead',
        {
          content_category: item.item_categories,
          content_name: item.item_name,
          value: item.price,
          currency: 'USD',
        },
        { eventID: uid + ' ' + item.item_id }
      );
  } catch (e) {
    logError(e);
  }
}

export const getItems = (items: ProductDocument[]) => {
  return items.map((i) => getItem(i));
};

const getItem = (i: ProductDocument) => {
  return {
    item_id: i.id,
    item_name: i.title,
    item_brand: i.brand || '',
    item_categories: i.categories,
    item_thumbnail: i.thumbnail,
    item_slug: i.slug, 
    master: i.master || '',
    external_source: i.external_source || 'gearfocus',
    price: i.price,
    quantity: 1,
  };
};

export function pushToDataLayer(eventName: string, payload: Record<string, unknown>) {
  if (typeof window !== "undefined" && window.dataLayer) {
    // Clear the previous ecommerce object and other properties
    window.dataLayer.push({ ecommerce: null, search_term: null, method: null });

    const eventObject: Record<string, unknown> = {
      event: eventName,
      ...payload,
    };
    window.dataLayer.push(eventObject);
  }
}

export type EcommerceEventType =
  | "view_item_list"
  | "select_item"
  | "view_item"
  | "add_to_cart"
  | "remove_from_cart"
  | "view_cart"
  | "begin_checkout"
  | "add_shipping_info"
  | "add_payment_info"
  | "purchase"
  | "refund"

export const trackEvent = (eventType: EcommerceEventType, eventData: Record<string, any>) => {
  // if (!isProd) return; // TODO: Uncomment this line to disable tracking in non-production environments
  
  // Use setTimeout to make the call asynchronous
  setTimeout(() => {
    pushToDataLayer(eventType, {
      ecommerce: {
        ...eventData,
      },
    });
  }, 0);
};

/**
 * Formats an array of ProductDocument objects into a standardized analytics format.
 *
 * @param products - Array of product documents to format.
 * @param listId - Optional identifier for the product list.
 * @returns An array of formatted product item objects.
 */
export const formatProductItems = (products: ProductDocument[], listId?: string) => {
  return products.map((product) => ({
    item_id: product.sku,
    item_name: product.title,
    price: product.price,
    currency: "USD",
    discount:
      product.on_sale && !product.out_of_stock
        ? Math.round(product.og_price - product.price)
        : undefined,
    item_brand: product.brand,
    // Map each category to a unique property name based on its index
    ...product.categories?.reduce<Record<string, any>>((acc, category, index) => {
      acc[`item_category${index > 0 ? index : ""}`] = category;
      return acc;
    }, {}),
    // Include list identifier if provided
    ...(listId ? { item_list_id: listId, item_list_name: listId } : {})
  }));
};

/**
 * Formats an array of CartItem objects into a standardized analytics format.
 *
 * @param items - Array of cart items to format.
 * @returns An array of formatted cart item objects.
 */
export const formatCartItems = (items: CartItem[]) => {
  return items.map((item) => ({
    item_id: item.product_id,
    item_name: item.product_title,
    price: item.product_cost,
    currency: "USD",
    item_brand: item.product_brand,
    ...item.product_categories?.reduce<Record<string, any>>((acc, category, index) => {
      acc[`item_category${index > 0 ? index : ""}`] = category;
      return acc;
    }, {}),
    quantity: item.qty || 1,
  }));
}