// Import necessary modules and components
import RootLayout from '@c/layouts/RootLayout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FB_PIXEL_ID, GA_TRACKING_ID } from '@util/analytics/analytics.service';
import { getHostUrl } from '@util/index';
import { generateRandomId, logError } from '@util/logError';
import AuthProvider from 'context/AuthContext';
import { ChatProvider } from 'context/ChatContext';
import { ShoppingCartProvider } from 'context/ShoppingCartContext';
import { StripeProvider } from 'context/StripeContext';
import { ToastProvider } from 'context/ToastContext';
import { NextPage } from 'next';
import type { AppProps, AppType } from 'next/app';
import Head from 'next/head';
import { useRouter as useNavigation } from 'next/navigation';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/globals.css';
import AppErrorBoundary from './AppErrorBoundary';
import { HeadMetaTag } from './blog/[...slug]';

import ConsentBanner from '@c/termly/ConsentBanner';


// Define custom types
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// Create a QueryClient instance with default options
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

// A component to provide context providers and error handling
function ProviderProvider({ children }: { children: ReactNode }) {
  const router = useNavigation();
  const id = useMemo(() => generateRandomId(), []);

  return (
    <ErrorBoundary
      onError={(error, { componentStack }) => {
        logError(error, id, componentStack);
      }}
      fallbackRender={({ error }: { error: Error }) => {
        const msg = error instanceof Error ? error.message : error;
        return <AppErrorBoundary message={msg} id={id} />;
      }}
      onReset={() => router.refresh()}
    >
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <StripeProvider>
            <ToastProvider>
              <ShoppingCartProvider>
                <ChatProvider>{children}</ChatProvider>
                <ConsentBanner websiteUUID='284e71c1-d4f7-4908-8af1-53ec89a77b0e' />
              </ShoppingCartProvider>
            </ToastProvider>
          </StripeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
// Define the main App component
const App: AppType = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const layout = getLayout(<Component {...pageProps} />);
  const showDots = pageProps.title && pageProps.title.length > 87;

  const defaultTitle = router.asPath.includes('/dashboard')
    ? 'Dashboard | Gear Focus'
    : 'Gear Focus - Buy and Sell Camera Gear';

  // Initialize dataLayer
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
  }, [])

  // Set canonical URL for SEO
  const canonicalUrl = router.query.tab
    ? `https://www.gearfocus.com/shop-seller/gerald-undone/${router.query.tab}`
    : `https://www.gearfocus.com${router.asPath.split('?')[0]}`;

  return (
    <RootLayout>
      <Head>
        <title>
          {pageProps.title
            ? `${pageProps.title?.slice(0, 90)}${showDots ? '...' : ''}`
            : defaultTitle}
        </title>

        {pageProps.metaTags?.map((tag: HeadMetaTag, i: number) => (
          <meta
            key={tag.key || i}
            property={tag.property}
            content={tag.content}
            {...tag.attributes}
          />
        ))}

        <meta
          http-equiv="Content-Security-Policy"
          content="
            default-src *;
            style-src 'self' 'unsafe-inline' https://fonts.googleapis.com https://static.klaviyo.com;
            style-src-elem 'self' 'unsafe-inline' 
              https://fonts.googleapis.com 
              https://static.klaviyo.com
              https://static-tracking.klaviyo.com;
            script-src 'self' 'unsafe-inline' 'unsafe-eval' 'unsafe-hashes'
              http://www.google.com
              https://js.stripe.com
              https://r.stripe.com
              https://www.googletagmanager.com
              https://cdnjs.cloudflare.com
              https://connect.facebook.net
              https://maps.googleapis.com
              https://apis.google.com
              https://pagead2.googlesyndication.com
              https://tpc.googlesyndication.com
              https://googleads.g.doubleclick.net
              https://vercel.live
              https://www.gstatic.com
              https://static.hotjar.com
              https://script.hotjar.com
              https://us-central1-gear-focus-staging.cloudfunctions.net
              https://us-central1-gear-focus.cloudfunctions.net
              https://firestore.googleapis.com
              https://gear-focus-default-rtdb.firebaseio.com
              https://gear-focus-staging-default-rtdb.firebaseio.com
              https://s-usc1a-nss-2032.firebaseio.com
              https://s-usc1f-nss-2512.firebaseio.com
              https://s-usc1a-nss-2039.firebaseio.com
              https://s-usc1a-nss-2041.firebaseio.com
              https://ss.gearfocus.com
              https://warm.gearfocus.com
              https://rr.revenueroll.com
              https://stapecdn.com/dtag/v8.js
              https://tracker.metricool.com/resources/be.js
              https://static.klaviyo.com
              https://static-tracking.klaviyo.com
              https://www.googleadservices.com
              https://rr.revenueroll.com
              https://bat.bing.com
              https://app.termly.io
              https://ep2.adtrafficquality.google;
            font-src *
              https://fonts.googleapis.com 
              https://fonts.gstatic.com
              https://js.stripe.com;
            img-src * blob: data:;
            frame-src 'self' 
              https://www.youtube.com
              https://www.google.com 
              https://js.stripe.com
              https://td.doubleclick.net
              https://www.googletagmanager.com
              https://ss.gearfocus.com
              https://gear-focus.firebaseapp.com
              https://s-usc1a-nss-2041.firebaseio.com
              https://s-usc1f-nss-2537.firebaseio.com
              https://s-usc1f-nss-2538.firebaseio.com
              https://googleads.g.doubleclick.net;
          "
        />

        <meta name="google-adsense-account" content="ca-pub-5059820352612148" />

        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          key="og:site_name"
          property="og:site_name"
          content="GearFocus"
        />
        <meta
          key="og:url"
          property="og:url"
          content={getHostUrl() + router.asPath}
        />
        <meta name="author" content="Gear Focus" />
        <meta charSet="UTF-8" />

        <link rel="canonical" href={canonicalUrl} />
        <link rel="dns-prefetch" href="https://google.com" />
        <link rel="dns-prefetch" href="https://gearfocus.b-cdn.net" />

      </Head>

      {/* APPLICATION CONTENT */}
      <ProviderProvider>{layout}</ProviderProvider>

      {/* ANALYTICS & TRACKING SCRIPTS */}

      {/* UTILITY SCRIPTS */}
      <Script
        src="https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js"
        strategy="lazyOnload"
        async
      />

    </RootLayout>
  );
};

export default App;
